import React, { useState } from 'react';
import ListItem from "@mui/material/ListItem";
import { NavLink } from 'react-router-dom'
import './style.css';

const menus = [
    {
        id: 1,
        title: 'Le Mariage',
        link: '/'
    },
    {
        id: 2,
        title: 'Où se loger ?',
        link: '/hebergements'
    },
    {
        id: 3,
        title: 'Voyage de noce',
        link: '/voyage-de-noce'
    }
]

const MobileMenu2 = () => {

    const [menuActive, setMenuState] = useState(false);

    return (
        <div>
            <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
                <div className="menu-close">
                    <div className="clox" onClick={() => setMenuState(!menuActive)}><i className="ti-close"></i></div>
                </div>

                <ul className="responsivemenu">
                    {menus.map((item, mn) => {
                        return (
                            <ListItem key={mn}>
                                <NavLink to={item.link}>{item.title}</NavLink>
                            </ListItem>
                        )
                    })}
                </ul>

            </div>

            <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
                <button type="button" className="navbar-toggler open-btn">
                    <span className="icon-bar first-angle"></span>
                    <span className="icon-bar middle-angle"></span>
                    <span className="icon-bar last-angle"></span>
                </button>
            </div>
        </div>
    )
}

export default MobileMenu2;
import React, { Fragment } from 'react';
import Hero from '../../components/Hero/hero';
import StorySection from '../../components/StorySection2/StorySection2';
import PortfolioSection from '../../components/PortfolioSection';
import RsvpSection from '../../components/RsvpSectionS3/RsvpSectionS3';
import EventSection from '../../components/EventSection/EventSection';
import BrideGrooms from '../../components/BrideGrooms';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'
import NavBar from '../../components/Navbar/Navbar'

const HomePage = () => {

    return (
        <Fragment>
            <NavBar />
            <Hero />
            <EventSection/>
            <PortfolioSection />
            <RsvpSection/>  
            <StorySection />
            <BrideGrooms />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default HomePage;
import React, { Fragment } from 'react'
import { Zoom } from "react-awesome-reveal";
import SectionTitle from '../SectionTitle'

import sImg1 from '../../images/photos/story/1- Rectangle.jpg'
import sImg2 from '../../images/photos/story/2- Rectangle.jpg'
import sImg3 from '../../images/photos/story/3- Carre.jpg'
import sImg4 from '../../images/photos/story/4- Rectangle.jpg'
import sImg5 from '../../images/photos/story/5- Carre.jpg'
import sImg6 from '../../images/photos/story/elle-a-dit-oui.jpeg'
import sImg7 from '../../images/photos/story/ski.jpeg'
import sImg8 from '../../images/photos/story/home.jpg'

import shape1 from '../../images/story/shape.png'
import shape2 from '../../images/story/shape2.png'
import shape3 from '../../images/story/shape3.png'

import bshape1 from '../../images/story/flower-shape1.svg'
import bshape2 from '../../images/story/flower-shape2.svg'
import bshape3 from '../../images/story/flower-shape3.svg'
import bshape4 from '../../images/story/flower-shape4.svg'

const Storys = [
    {
        sImg: sImg1,
        shape: shape3,
        title: 'La rencontre',
        date: '15 avril 2022',
        description: <Fragment>C’est par un pur hasard que Florie-Anne s’installe à côté de Julien, un lundi matin, au début d’une nouvelle aventure professionnelle à Montpellier. Premiers échanges cordiaux entre la bouilloire et la machine à café, il faudra attendre l’achat par Julien de sa fameuse voiture dorée pour qu’ils aient enfin un vrai sujet de discussion ! Quelques soirées entres amis, des <a href="/easter-eggs/make-me-un-poco-loco.jpeg" target="_blank">cocktails</a> et des essais de <a href="/easter-eggs/patisserie.jpeg" target="_blank">pâtisserie vegan</a> plus tard, un vrai lien se tisse.</Fragment>,
    },
    {
        sImg: sImg7,
        shape: shape2,
        title: "Cours particulier",
        date: '14 janvier 2023',
        description: <Fragment>Première aventure : lors d’un week-end ski entre collègues, Julien prendra son courage à deux mains pour affronter la pente et dévaler les pistes. Son premier cours de ski, qui finira quand même en pistes bleues, <a href="/easter-eggs/ski.mp4" target="_blank">impressionne</a> sa monitrice du jour.</Fragment>,
        order1: 'order-lg-2 order-1',
        order2: 'order-lg-1 order-2',
    },
    {
        sImg: sImg2,
        shape: shape3,
        title: 'Coup de folie à Lyon !',
        date: '02 mai 2023',
        description: <Fragment>Une envie qui trottait en tête depuis longtemps… Alors quitte à franchir le pas : autant le faire à deux ! Deux poissons qui nagent et qui se complètent. Une nouvelle expérience et de bons souvenirs à deux !</Fragment>,
    },
    {
        sImg: sImg3,
        shape: shape2,
        title: "Vino & Pasta",
        date: '19 mai 2023',
        description: <Fragment>Premier voyage ensemble à l’occasion du mariage d’une amie de Florie-Anne. Un <a href="/easter-eggs/roadtrip.mp4" target="_blank">roadtrip</a> de 8 heures jusqu’à Padoue, l’occasion de se découvrir différemment, de manger des pizzas et de boire du bon vin. Heureusement la voiture, bien que <a href="/easter-eggs/titine.jpeg" target="_blank">dorée</a>, est confortable !</Fragment>,
        order1: 'order-lg-2 order-1',
        order2: 'order-lg-1 order-2',
    },
    {
        sImg: sImg4,
        shape: shape2,
        title: 'Musique & copains',
        date: '8 juillet 2023',
        description: <Fragment>Week-end à Six-Fours-les-Plages pour le festival de Rock alternatif « Le Pointu » sur la presqu’île du Gaou. 3 jours de <a href="/easter-eggs/wesh.jpg" target="_blank">plage</a>, de <a href="/easter-eggs/confettiiiissss.mp4" target="_blank">musique</a> et d’amis.</Fragment>,
    },
    {
        sImg: sImg8,
        shape: shape3,
        title: 'Pancakes au matcha',
        date: '27 août 2023',
        description: <Fragment>Tout un nouveau quotidien qui commençe : tous deux en télétravail depuis chez <a href="/easter-eggs/suspendue.jpg" target="_blank">nous</a>, à Montpellier. L'occasion de se créer de nouvelles <a href="/easter-eggs/cocktail.jpg" target="_blank">habitudes</a>, comme les <a href="/easter-eggs/brunch.jpg" target="_blank">pancakes</a> du dimanche matin, entre deux déplacements sur Paris ou Bordeaux.</Fragment>,
        order1: 'order-lg-2 order-1',
        order2: 'order-lg-1 order-2',
    },
    {
        sImg: sImg5,
        shape: shape1,
        title: "Il a dit oui !",
        date: '28 octobre 2023',
        description: <Fragment>A l'issue d'une enquête qui a mené Julien aux <a href="/easter-eggs/escape-game.jpg" target="_blank">quatre coins</a> de Montpellier, elle l'a demandé en mariage... et <a href="/easter-eggs/magnifique-aussi.jpeg" target="_blank">il</a> a dit oui !</Fragment>,
    },
    {
        sImg: sImg6,
        shape: shape3,
        title: 'Elle a dit oui !',
        date: '16 novembre 2023',
        description: <Fragment>Une cabane dans la montagne, un bain nordique, un cocktail inventé pour l'occasion, un ciel étoilé. Un <a href="/easter-eggs/magnifique.jpeg" target="_blank">magnifique cadre</a> pour une demande et elle a dit oui !</Fragment>,
        order1: 'order-lg-2 order-1',
        order2: 'order-lg-1 order-2',
    }

]


const StorySection2 = () => {
    return (
        <section className="wpo-story-section section-padding" id="story">
            <div className="container">
                <SectionTitle subTitle={'Notre histoire'} MainTitle={'Comment la magie a opérée'} />
                <div className="wpo-story-wrap">
                    {
                        Storys.map((story, st) => (
                            <div className={`wpo-story-item item-${st}`} key={st}>
                                <div className="wpo-story-img-wrap">
                                    <Zoom direction="up" duration="1000" triggerOnce="true">
                                        <div className="wpo-story-img">
                                            <img src={story.sImg} alt="" />
                                        </div>
                                    </Zoom>
                                    <div className="clip-shape">
                                        <svg viewBox="0 0 382 440" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M191 0L381.526 110V330L191 440L0.474411 330V110L191 0Z" />
                                        </svg>
                                    </div>
                                    <div className="wpo-img-shape">
                                        <img src={story.shape} alt="" />
                                    </div>
                                </div>
                                <div className="wpo-story-content">
                                    <div className="wpo-story-content-inner">
                                        <span>{story.date}</span>
                                        <h2>{story.title}</h2>
                                        <p>{story.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))

                    }
                </div>
            </div>
            <div className="flower-shape-1">
                <img src={bshape1} alt="" />
            </div>
            <div className="flower-shape-2">
                <img src={bshape2} alt="" />
            </div>
            <div className="flower-shape-3">
                <img src={bshape3} alt="" />
            </div>
            <div className="flower-shape-4">
                <img src={bshape4} alt="" />
            </div>
        </section>
    )
}

export default StorySection2;

import React from "react";
import Header from "../Header/Header";

export default function Navbar(props) {

  return (
    <div className="fixed-navbar">
        <Header hclass={props.hclass} Logo={props.Logo} topbarNone={props.topbarNone}/>
    </div>
  ); 
}
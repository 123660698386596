import React from 'react'

const Accomodations = () => (
    <section className="wpo-blog-pg-section p-60 team-pg-area">

        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="wpo-blog-content">
                        <div className="post"> 
                            <h3 id="gites"><a href="#gites">Gîtes</a></h3>

                            <p> Le mariage aura lieu entre Montpellier et Clermont l'Hérault, à 45 minutes de Montpellier.
                                Nous conseillons à tous nos invités de loger sur les communes environnantes : Gignac, Pouzols ou Popian, 
                                qui sont à 20 minutes de route du lieu.</p>
                            
                            <p>Nous avons fait une sélection d'une dizaine de gîtes et auberges que vous retrouverez sur la carte ci-dessous. Etant donné que notre mariage sera célébré pendant la période estivale, nous ne pouvons que vous recommander de choisir rapidement votre hébergement.</p>
                            <p>Nous avons sélectionné des lieux prévus pour accueillir entre 4 et 32 adultes pour que vous puissiez vous retrouver en famille, avec un budget entre 70€ et 130€ la nuit.</p>

                            <iframe title="herbergements" src="https://www.google.com/maps/d/u/0/embed?mid=1_K-mp4-T9_issCE5rPHcY38M_YbSmRc&ehbc=2E312F&noprof=1" width="100%" height="480"></iframe>
                            <p class="link"><a href="https://www.google.com/maps/d/u/0/viewer?mid=1_K-mp4-T9_issCE5rPHcY38M_YbSmRc&ll=43.64079776126892%2C3.5405606910651066&z=12" target="_blank" rel="noreferrer">Accès direct à la carte</a></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="wpo-blog-content">
                        <div className="post"> 
                            <h3 id="comment-venir"><a href="#comment-venir">Comment venir ?</a></h3>
                            
                            <p>La route pour mener au Château Bas d'Aumelas, à ne pas confondre avec le <a href="https://www.google.fr/maps/@43.5979944,3.6093182,0a,75y,90t/data=!3m7!1e2!3m5!1sAF1QipP2AHo-giTVa-CtEWzROFvSCIVQ4QJornAffioU!2e10!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipP2AHo-giTVa-CtEWzROFvSCIVQ4QJornAffioU%3Dw150-h150-k-no-p!7i4032!8i2268?entry=ttu" target="_blank">château d'Aumelas</a>, les ruines d'un ancien château, serpente entre les vignes. Vous pourrez vous garez directement sur le parking du domaine, qui sera surveillé par un agent de sécurité.</p>
                            <p>Nous déconseillons tout de même la route de retour et proposons un service de navette, sur demande dans le RSVP, pour vous menez au site et vous ramenez à vos logements.</p>
                            <p>Les navettes pourrons vous transporter de votre hébergement au château entre 14h et 15h30 et vous raccompagner à votre hébergement entre minuit et 4h.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="wpo-blog-content">
                        <div className="post"> 
                            <h3 id="tipis"><a href="#tipis">Les tipis</a></h3>
                            
                            <p>Le château Bas d'Aumelas a un partenariat avec <a href="https://www.etoilesettoiles.com/" target="_blank" rel="noreferrer">Étoiles et Toiles</a> un prestataire de location de grands tipis et de sanitaire pour loger en tentes entre 4 et 10 personnes.</p>
                            <p>Nous proposons donc à nos amis de loger directement sur place, en tipis de 4 personnes maximum, pour un budget de 50€ la nuit, pour profiter au mieux du week-end et de la soirée.</p>
                            <p>Si cet hébergement vous intéresse, merci de nous prévenir au plus vite !</p>

                            <p class="link"><img src="https://jimdo-storage.freetls.fastly.net/image/111718474/73e8f7b7-ceaa-48d3-82ee-d4bdf1905ca8.jpg?format=pjpg&quality=80,90&auto=webp&disable=upscale&width=400&trim=0,864,0,864" /></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="wpo-blog-content">
                    <div className="post"> 
                        <h3 id="surplace">Logements sur place</h3>
                        <p>Le château dispose d'une dizaine de chambres que nous réservons à nos parents, frères et sœurs et témoins.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default Accomodations
import React from "react";
import { Slide } from "react-awesome-reveal";
import SectionTitle from "../SectionTitle";

const BrideGrooms = (props) => {
  return (
    <section className={`wpo-team-section p-60`}>
      <div className="container">
        <SectionTitle subTitle={'VIP'} MainTitle={'Témoins & maîtresse de cérémonie'}  s2Class={"pb-20"}/>
        <div className="wpo-team-wrap scene-2" id="scene-2">
          <div className="row">
          
              <div className="col col-lg-3 col-md-6 col-sm-6 col-12 layer">
                <Slide direction="up" duration={1000} triggerOnce="true">
                  <div className="wpo-team-item">
                   
                    <div className="wpo-team-text">
                      <h3>Guillaume Bincaz</h3>
                      <p>06 78 03 33 48</p>
                      <span>Frère de la mariée</span>
                    </div>

                  </div>
                </Slide>
              </div>

              <div className="col col-lg-3 col-md-6 col-sm-6 col-12 layer">
                <Slide direction="up" duration={1200} triggerOnce="true">
                  <div className="wpo-team-item">
                   
                    <div className="wpo-team-text">
                      <h3>Violaine De Freslon</h3>
                      <p>06 95 15 93 52</p>
                      <span>Amie d'amour de la mariée</span>
                    </div>

                  </div>
                </Slide>
              </div>

              <div className="col col-lg-3 col-md-6 col-sm-6 col-12 layer">
                <Slide direction="up" duration={1400} triggerOnce="true">
                  <div className="wpo-team-item">
                   
                    <div className="wpo-team-text">
                      <h3>Jean-Luc Lafont</h3>
                      <p>06 63 38 66 53</p>
                      <span>Frère du marié</span>
                    </div>

                  </div>
                </Slide>
              </div>

              <div className="col col-lg-3 col-md-6 col-sm-6 col-12 layer">
                <Slide direction="up" duration={1600} triggerOnce="true">
                  <div className="wpo-team-item">
                   
                    <div className="wpo-team-text">
                      <h3>Laila Bougeret</h3>
                      <p>06 42 05 23 47</p>
                      <span>Amie du marié</span>
                    </div>

                  </div>
                </Slide>
              </div>

          </div>
          <div className="row">
          
              <div className="col col-lg-4 col-md-3 col-sm-3 col-12 layer"></div>
            
              <div className="col col-lg-4 col-md-6 col-sm-6 col-12 layer">
                <Slide direction="up" duration={1800} triggerOnce="true">
                  <div className="wpo-team-item">
                   
                    <div className="wpo-team-text">
                      <h3>Juliette Arfeuillere</h3>
                      <p>06 37 96 88 64</p>
                      <span>Maîtresse de cérémonie</span>
                    </div>

                  </div>
                </Slide>
              </div>

              <div className="col col-lg-4 col-md-3 col-sm-3 col-12 layer"></div>

          </div>
        </div>
      </div>
    </section>

  )
}

export default BrideGrooms;
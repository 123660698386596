import React, { Fragment } from 'react'
import { Slide } from "react-awesome-reveal";
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/photos/events/event-1b.jpg'
import sImg2 from '../../images/photos/events/event-2.jpg'
import sImg3 from '../../images/photos/events/event-3.jpg'
import sImg4 from '../../images/photos/events/event-4.jpg'
import LocationMap from './Modal'

const Events = [
    {
        Simg: sImg1,
        title: 'La cérémonie',
        li1: <Fragment>Samedi 13 juillet 2024<br /><strong>16h00</strong></Fragment>,
        li2: <Fragment>Arrivée conseillée avant 15h30</Fragment>,
        animation:'1200',
    },
    {
        Simg: sImg2,
        title: 'Le vin d\'honneur ',
        li1: <Fragment>Samedi 13 juillet 2024<br/><strong>18h00</strong></Fragment>,
        li2: <Fragment>En musique 🎸 !</Fragment>,
        animation:'1400',
    },
    {
        Simg: sImg3,
        title: 'La soirée',
        li1: <Fragment>Samedi 13 juillet 2024<br /><strong>20h30</strong></Fragment>,
        li2: <Fragment>Repas et musique jusqu'au bout de la nuit</Fragment>,
        animation:'1600',
    },
    {
        Simg: sImg4,
        title: 'Le brunch',
        li1: <Fragment>Dimanche 14 juillet 2024<br /><strong>à partir de 11h00</strong></Fragment>,
        li2: <Fragment>Château à notre disposition jusqu'à 18h</Fragment>,
        animation:'1800',
    },

]

const EventSection = (props) => {
    return (
        <>
        <section className="wpo-event-section section-padding" id="event">
            <div className="container">
                <SectionTitle subTitle={'Notre mariage'} MainTitle={'Quand ?'} />
                <div className="wpo-event-wrap">
                    <div className="row">
                        {Events.map((event, eitem) => (
                            <div className="col col-lg-3 col-md-6 col-12" key={eitem}>
                                <Slide direction="up" duration={event.animation} triggerOnce="true">
                                    <div className="wpo-event-item">
                                        <div className="wpo-event-img">
                                            <div className="wpo-event-img-inner">
                                                <img src={event.Simg} alt="" />
                                            </div>
                                        </div>
                                        <div className="wpo-event-text">
                                            <div className="title">
                                                <h2>{event.title}</h2>
                                            </div>
                                            <ul>
                                                <li>{event.li1}</li>
                                                <li><i>{event.li2}</i></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="container">
                <SectionTitle MainTitle={'Où ?'} />
                <div className="wpo-event-wrap">
                    <div className="row">
                        <div className="col col-lg-4 col-md-3 col-0"></div>
                        <div className="col col-lg-4 col-md-6 col-12">
                            <Slide direction="up" duration={1800} triggerOnce="true">
                                <div className="wpo-event-item">
                               
                                    <div className="wpo-event-text">
                                        <div className="title">
                                            <h2>Château Bas d'Aumelas</h2>
                                        </div>
                                        <ul className='large'>
                                            <li>L'ensemble du mariage se déroulera au Château Bas d'Aumelas à 30 minutes de Montpellier</li>
                                            <li><LocationMap /></li>
                                        </ul>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                        <div className="col col-lg-4 col-md-3 col-0"></div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default EventSection;
import React from 'react'
import pImg1 from '../../images/photos/slide/1 - 1200px.jpg'
import pImg2 from '../../images/photos/slide/2 - 1200px.jpg'
import pImg3 from '../../images/photos/slide/3 - 1200px.jpg'
import pImg4 from '../../images/photos/slide/4 - 1200px.jpg'
import pImg5 from '../../images/photos/slide/5 - 1200px.jpg'
import pImg6 from '../../images/photos/slide/6 - 1200px.jpg'
import pImg7 from '../../images/photos/slide/7 - 1200px.jpg'
import pImg8 from '../../images/photos/slide/8 - 1200px.jpg'
import pImg9 from '../../images/photos/slide/9 - 1200px.jpg'
import pImg10 from '../../images/photos/slide/10 - 1200px.jpg'
import pImg11 from '../../images/photos/slide/11 - 1200px.jpg'
import pImg12 from '../../images/photos/slide/12 - 1200px.jpg'
import pImg13 from '../../images/photos/slide/13 - 1200px.jpg'

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const settings = {
    dots: false,
    arrows: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};


const Portfolios = [pImg1, pImg2, pImg3, pImg4, pImg5, pImg6, pImg7, pImg8, pImg9, pImg10, pImg11, pImg12, pImg13]


const PortfolioSection = (props) => {

    const [open, setOpen] = React.useState(false);

    return (

        <section className={`wpo-portfolio-section section-padding ${props.ptClass}`} id="gallery">
            <h2 className="hidden">some</h2>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="portfolio-grids gallery-container clearfix portfolio-slide">
                            <Slider {...settings}>
                                {Portfolios.map((image, i) => (
                                    <div className="grid" key={i}>
                                        <div className="img-holder" onClick={() => setOpen(true)}>
                                            <img src={image} alt="" className="img img-responsive" />
                                            <div className="hover-content">
                                                <i className="ti-plus"></i>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={Portfolios.map(url => { return {src: url} })}
            />
        </section>
    )
}

export default PortfolioSection;
import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import NavBar from '../../components/Navbar/Navbar'
import Honeymoon from '../../components/Honeymoon/Honeymoon';

const HoneymoonPage =() => {
    return(
        <Fragment>
            <NavBar  hclass={'wpo-header-style-1'}/>

            <PageTitle pageTitle={'Notre voyage de noce'}/> 
           
            <Honeymoon />

            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default HoneymoonPage;

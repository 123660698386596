import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import NavBar from '../../components/Navbar/Navbar'
import Accomodations from '../../components/Accomodations/Accomodations';
import ScrollToAnchor from '../../components/scrollToAnchor/scrollToAnchor';

const AccomodationPage =() => {
    return(
        <Fragment>
            <NavBar  hclass={'wpo-header-style-1'}/>

            <PageTitle pageTitle={'Où se loger ?'} pagesub={'Notre sélection de gîtes et tipis'}/> 
           
            <Accomodations />

            <Footer />
            <Scrollbar />
            <ScrollToAnchor />
        </Fragment>
    )
};
export default AccomodationPage;

import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useForm } from "react-hook-form"

const RSVPFrom = () => {

    const HOOK_URL = "https://hook.eu2.make.com/rwxkfk62935gyy5gero5pyb65lq28nyk"
    //const HOOK_URL = "https://run.mocky.io/v3/199cfd89-9299-425a-9bcb-a1bd7c7a0dba" // success
    //const HOOK_URL = "https://run.mocky.io/v3/8c235eef-9e49-49c0-b6c1-0dc745aa158b" // error

    const { register, handleSubmit, formState: { errors }, watch } = useForm()
    const [status, setStatus] = useState();
    const attending = watch("attend")

    const onSubmit = async (data) => {

        setStatus()

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({...data, date: new Date()}),
            headers: { 
                "Content-Type": "application/json"
            }
        };

        await fetch(HOOK_URL, requestOptions)
            .then(response => response.text())
            .then(response =>setStatus(response == "Accepted" ? "success" : "error"))
            .catch(err => setStatus("error"))
    }

    return <>

        {(status === "success") && <>
            <div className="contactSuccess">
                <p><strong>Votre réponse nous a été transmise avec succès !</strong></p>
                <p>{attending === "yes" && <>Nous sommes ravi d'apprendre que vous serez parmis nous pour partager cette magnifique journée !</>}</p>
                <p>{attending === "no" && <>Nous regrettons de ne pas vous avoir parmi nous le 13 juillet, mais ce n'est que partie remise !</>}</p>
                
                <video controls autoPlay>
                    <source src="/easter-eggs/eglise-crop.mp4" type="video/mp4" />
                </video>

            </div>
        </>}

        {(status !== "success") && <form onSubmit={handleSubmit(onSubmit)} className="contact-validation-active" >

            <div className="wpo-section-title">
                <h2>
                    Confirmez votre présence&nbsp;
                    {attending === "yes" && <>🎉</>}
                    {attending === "no" && <>😞</>}
                </h2>
            </div>
                                

            {(status) && <>
                <div className="criticalMessage">
                    <p>Un problème est survenue durant l'envoi de votre réponse.</p>
                    <p>Si le problème persiste, n'hésitez pas à nous contacter directement (coordonnées en bas du site).</p>
                </div>
            </>}
            
            <div className="row">
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Prénom, Nom *" 
                            {...register("name", { required: true })} />
                        {errors.name && <div className="errorMessage">Champ obligatoire</div>}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            type="tel"
                            name="tel"
                            className="form-control"
                            placeholder="Téléphone *"
                            {...register("phone", { required: true })} />
                        {errors.phone && <div className="errorMessage">Champ obligatoire</div>}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="radio-buttons">
                        <p>
                            <input type="radio" id="attend" name="radio-group" value="yes" {...register("attend", { required: true })}/>
                                <label htmlFor="attend">Je viens avec plaisir !</label>
                        </p>
                        <p>
                            <input type="radio" id="not" name="radio-group" value="no" {...register("attend", { required: true })} />
                            <label htmlFor="not">Je ne suis pas disponible</label>
                        </p>
                        {errors.attend && <p className="errorMessage">Champ obligatoire</p>}
                    </div>
                    
                </div>
                

                {attending === "yes" && <>
                    <div className="col col-lg-12 col-12">
                        <div className="form-field">
                            <textarea
                                className="form-control"
                                name="guest"
                                placeholder='Je serais accompagné·e de...'
                                {...register("guest")}
                            >
                            </textarea>
                        </div>
                    </div>

                    <div className="col col-lg-12 col-12">
                        <div className="form-field">
                        <input
                                type="input"
                                name="meal"
                                className="form-control"
                                {...register("meal")}
                                placeholder="Allergies/préférences alimentaires ?" />
                        </div>
                    </div>
                    <div className="col col-lg-12 col-12">
                        <div className="form-field">
                        <input
                                type="input"
                                name="music"
                                className="form-control"
                                {...register("music", { required: true })}
                                placeholder="Votre musique préférée ? *" />
                        {errors.music && <div className="errorMessage">Champ obligatoire</div>}   
                        </div>
                    </div>

                                        
                    <div className="col col-lg-12 col-12">
                        
                        <div className="form-field">
                            <select 
                                className="form-control" 
                                {...register("transport", { required: true})}>
                                <option value="">Bénéficier du service de navette ? *</option>
                                <option value="yes">Oui, je veux faire la fête jusqu'au bout de la nuit</option>
                                <option value="no">Non, nous sommes sûr de rentrer en sécurité</option>
                            </select>
                            {errors.transport && <p className="errorMessage">Champ obligatoire</p>}
                        </div>
                        <i className='small'>Nous tenons à ce que tous nos invité·e·s rentrent en sécurité à leur hébergement, pour cela nous mettons en place un service de navette à la demande. <NavLink to="/hebergements#comment-venir" target="_blank">En savoir plus</NavLink></i>
                    </div>
                </>}
            </div>
            <div className="submit-area">
                <button type="submit" className="theme-btn">Envoyer !</button>
            </div>
        </form>}
    </>
}

export default RSVPFrom;
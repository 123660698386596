import React from 'react'
import { Slide } from "react-awesome-reveal";
import shape1 from '../../images/footer-shape-1.svg'
import shape2 from '../../images/footer-shape-2.svg'

const Footer = (props) => {
    return (
        <footer className={`wpo-site-footer ${props.footerClass}`}>
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row ">
                        <div className="col col-xl-4  col-lg-4 col-md-4 col-sm-4 col-12">
                                <div className="widget wpo-service-link-widget">
                                    <div className="widget-title">
                                        <h3>Florie-Anne Bincaz</h3>
                                    </div>
                                    <div className="contact-ft">
                                        <p><a href="mailto:florie.bincaz@gmail.com">florie.bincaz@gmail.com</a></p>
                                        <p><a href="tel:+33664073631" className="nopointpoint">06 64 07 36 31</a></p>
                                    
                                    </div>
                                </div>
                        </div>

                        <div className="col col-xl-4  col-lg-4 col-md-4 col-sm-4 col-12">
                            <div className="widget wpo-service-link-widget">
                                <div className="widget-title">
                                    <h3>Julien Lafont</h3>
                                </div>
                                <div className="contact-ft">
                                <p><a href="mailto:julien.lafont@gmail.com">julien.lafont@gmail.com</a></p>
                                <p><a href="tel:+33676015812" className="nopointpoint">06 76 01 58 12</a></p>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="col col-xl-4  col-lg-4 col-md-4 col-sm-4 col-12">
                            <div className="widget wpo-service-link-widget">
                                <div className="widget-title">
                                    <h3>Adresse</h3>
                                    
                                </div>
                                <div className="contact-ft">
                                    <p>330 rue Fra Angelico, Apt 27</p>
                                    <p>34000 Montpellier</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            
            <div className="ft-shape-1">
                <Slide direction="left" duration="1000" triggerOnce="true">
                    <img src={shape1} alt="" />
                </Slide>
            </div>
            <div className="ft-shape-2">
            <Slide direction="right" duration="1200" triggerOnce="true">
                    <img src={shape2} alt="" />
                </Slide>
            </div>
        </footer>
    )
}

export default Footer;
import React from 'react'
import { NavLink } from 'react-router-dom'
import MobileMenu from '../MobileMenu2/MobileMenu2'

const Header = (props) => {

    return (

        <header id="header" className={props.topbarNone}>
            <div className={`wpo-site-header ${props.hclass}`}>
                <nav className="navigation navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                                <div className="mobail-menu">
                                    <MobileMenu/>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-1 col-1">
                                <div id="navbar" className="collapse navbar-collapse navigation-holder">
                                    <button className="menu-close"><i className="ti-close"></i></button>
                                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                                        <li className="menu-item-has-children">
                                            <NavLink to="/">Le mariage</NavLink>    
                                        </li>
                                        <li className="menu-item-has-children">
                                            <NavLink to="/hebergements">Où se loger ?</NavLink>    
                                        </li>
                                        <li className="menu-item-has-children">
                                            <NavLink to="/voyage-de-noce">Voyage de noce</NavLink>    
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header
import React from 'react'
import japan from '../../images/photos/japan.jpeg'

const Honeymoon = () => (
    <section className="wpo-blog-pg-section p-60 team-pg-area">
        <div className='container'>
            <div className="row">
                <div className="col">
                    <div className="wpo-blog-content">
                        <div className="post"> 
                            <h3 id="japan"><a href="#japan">Direction le pays du soleil levant !</a></h3>
                            <p>Nous ne ferons pas de liste de mariage et nous n'attendons aucune participation. Si toutefois vous le souhaitez, une urne sera à votre disposition le jour de notre mariage, pour recevoir vos lettres ou pour participer à nous offrir notre voyage de noce.</p>
                            <p><img src={japan} /></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default Honeymoon
import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";

import Homepage from '../HomePage/HomePage'
import AccomodationPage from '../AccomodationPage/AccomodationPage';
import HoneyMoonPage from '../HoneymoonPage/HoneymoonPage'

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/hebergements' element={<AccomodationPage />} />
          <Route path='/voyage-de-noce' element={<HoneyMoonPage />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
